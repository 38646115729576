/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Button } from 'reactstrap';

const committeeTableRow = ({title, name}) => {
    return (
        <div className='committee-executive-table-row'>
          <Row>
            <Col md={5}>
                {title}
            </Col>
            <Col md={7}>
              :&nbsp;{name}
            </Col>
          </Row>
        </div>
    );
}

export default committeeTableRow;
