import { Card, Col } from 'reactstrap';


const CarouselCard = ({index,title,description,image,link}) => {
return(
<Col key={index} md="11">
    <Card className="card-shadow fixed-height-card">
        <a className="img-ho" href={link}>
            <img className="card-img-top" src={image} alt="Donation" />
        </a>
        <div>
            <h5 className="caroselcard-title">{title}</h5>
            <p className="caroselcard-description">{description}</p>
        </div>
        <div className="carosel-readmore-btn-wrapper">
            <a href={link} className='btn btn-basic-org waves-effect m-r-10 carosel-readmore-btn'>
                Read More
            </a>
        </div>
    </Card>
</Col>




)
}

export default CarouselCard;
