import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import HeaderBanner from "../../components/banner/banner";
import GreetingList from "../../components/GreeetingMaterial/media";

const MediaView = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <HeaderBanner heading="Gallery" />

          <GreetingList />
        </div>
      </div>
      <Footer />
    </div>
  );
};

MediaView.propTypes = {
  classes: PropTypes.object,
};

export default MediaView;
