/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import Rajith from "../../assets/images/portfolio/rajitha.png"
import Ruwan from "../../assets/images/portfolio/drruwan.png"

const bannerFunders = () => {
    return (
        <div className=" ">
            <div className="pt-5 pb-5 founder-content">
                <Container className="pt-3 pb-4">
                    <Row className="justify-content-start pl-0 pr-0">
                        <Col md={12} className="align-self-start text-start pl-0 pr-0">
                            <h2 className="title">
                            WHAT INSPIRED THE&nbsp;<span style={{ color: "#ED0F0F" }}>FOUNDERS&nbsp;</span>
                            </h2>
                            <h6 className="subtitle op-8 pt-3">
                            Cardiothoracic surgeons with years of experience have seen patients undergoing emotionally traumatic experiences when grappling with fi­nancial difficulties to obtain timely treatment to save their lives. For a doctor, such expe­riences can be devastating.<br/>
                            It was when Dr Rajitha De Silva was working as a cardiac sur­geon at the General Hospital in Colombo he came face-to-face with this reality and it was through these disturbing experiences that he developed the concept of evolving a fund to help poor patients to access cardiac surgery from the fee-levying sector in situations where waiting patiently for one’s turn on the long waiting list in a Government hospital could be fraught with risk and danger. Dr Ruvan Ekanayaka, the then Consultant Cardiologist at the General Hospital in Colombo shared with Dr Rajitha De Silva the same vision of wanting to save the lives of impoverished heart patients, and together we established the Heart to Heart Trust Fund in 2014. It is their belief that no heart patient in our country should lose his or her life on account of financial impoverish­ment. This is the noble thought that gives life and blood to the Heart to Heart Trust Fund.
                            </h6>
                            

                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='trustbanner'>
                <Row className="justify-content-center pl-0 pr-0">
                    <Col  className="align-self-center text-center">
                        <div className="gray-container">
                            <Container>
                                <Row className="justify-content-start pl-0 pr-0">
                                    <Col md={12} className="align-self-start text-start pl-0 pr-0">
                                        <h2 className="title">
                                        CO-&nbsp;<span style={{ color: "#ED0F0F" }}>FOUNDERS&nbsp;</span>
                                        </h2>
                                        <h6 className="sub-content op-8 pt-3">
                                        These two concerned and dedicated Doctors turned fruitful this much needed Trust Fund as per article appearing in the Sunday Times of 18th Janurary 2015, and Rivira of 15th March 2015.
                                        </h6>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center pl-0 pr-0 pt-5 pb-3">
                                    <Col md={5} className="align-self-center text-center pl-0 pr-0">
                                        <img src={Ruwan} alt="Doctor" />
                                        <h6 className="dr-name op-8 pt-3">
                                        DR. RUVAN A I EKANAYAKA
                                        </h6>
                                        <h6 className="dr-title op-8 pt-3">
                                            MBBS(Col), MD(Col), MRCP(UK)FRCP(Lond), FRCP(Edin), FRCP(Glsg), FCCP, FACC, FESC
                                            Eminent Consultant Cardiologist                                        
                                        </h6>
                                    </Col>
                                    <Col md={2}/>
                                    <Col md={5} className="align-self-center text-center pl-0 pr-0">
                                        <img src={Rajith} alt="Doctor" />
                                        <h6 className="dr-name op-8 pt-3">
                                            DR. RAJITHA Y DE SILVA
                                        </h6>
                                        <h6 className="dr-title op-8 pt-3">
                                            MBBS, MS(Col), MRCS(Eng), FRCS(C-Th), FCSSL
                                            Consultant Cardiothoracic Surgeon                                        
                                        </h6>
                                    </Col>
                                </Row>
                            </Container>
                            </div>
                        </Col>
                        
                        </Row>
            </div>
            
        </div>
    );
}

export default bannerFunders;
