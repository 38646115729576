import React from 'react';
import { Card, CardBody, Container, Label } from 'reactstrap';

const eventComponet = ({eventimage, derscription, title}) => {
    return (
        <div>
            <Container>
            <Card className="card-shadow" style={{height:"450px"}} >
                <div className="">
                    <a className="img-ho">
                        <img className="card-img-top" src={eventimage} alt={title} />
                    </a>
                </div>
                    <CardBody className="d-flex justify-content-center flex-column align-items-center">
                        <Label className="catagory-content" style={{fontWeight: '700', textTransform: 'uppercase'}}>{title}</Label>
                        <Label className="" style={{textAlign:'justify'}}>{derscription}</Label>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default eventComponet;
