import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";
import Components from "./views/components/components.jsx";
import CustomComponents from "./views/custom-components/custom-components.jsx";
import HomePage from "./Pages/Home/index";
import DonationPage from "./Pages/Donation";
import ContactPage from "./Pages/Contact"
import Abhayadhana from "./Pages/Abhayadhana"
import AboutUS from "./Pages/AboutUS";
import ProgressRpt from "./Pages/Progress";
import Volunteers from "./Pages/Volunteers"
import AllNews from "./Pages/NewsNStory"
import UnderDev from "./Pages/UnderDevelopment";
import MediaView from "./Pages/Media/index.js";
import GreedingMaterial from "./Pages/GreetingMeterial/index.js";
import 'antd/dist/reset.css';
const root = ReactDOM.createRoot(document.getElementById('root'));

var hist = createBrowserHistory();
root.render(
  <HashRouter history={hist}>
    <Provider store={store}>
      <Routes>
        <Route path="/custom-components" element={<CustomComponents />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/sample" element={<Components />} />
        <Route path="/donation" element={<DonationPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/aboutus" element={<AboutUS />} />
        <Route path="/abhayadhana" element={<Abhayadhana />} />
        <Route path="/progress" element={<ProgressRpt />} />
        <Route path="/volunteers" element={<Volunteers />} />
        <Route path="/newsandstoties" element={<AllNews/>} />
        <Route path="/underdevelopment" element={<UnderDev />} />
        <Route path="/greeeting-meterial" element={<GreedingMaterial />} />
        <Route path="/media" element={<MediaView />} />



      </Routes>
    </Provider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
