import { createSlice } from "@reduxjs/toolkit";
import { getDoners } from "../../../services/getDoners";

const donorsSlice = createSlice({
  name: 'doner',
  initialState: {
    doners: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDoners.fulfilled, (state, action) => {
        state.doners = action.payload;
        state.loading = false;
        console.log(action.payload);
      })
      .addCase(getDoners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDoners.rejected, (state, action) => {
        state.loading = false;
        state.error = 'Unable to get doner list (' + action.error.message + ')';
      });
  },
});

export default donorsSlice.reducer;
