/* eslint-disable */
import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';


const BecameFundraiserBanner = () => {
    return (
            <div className='trustbanner' >
                <Row className="justify-content-center pl-0 pr-0 " style={{"paddingLeft": 70}}>
                    <Col md={12} className="align-self-center text-center">
                        <div className="gray-container pt-5">
                        <Container className='quote-text 5t-5'>
                            <Row className="justify-content-start pt-5 pl-0 pr-0">
                            <Col md={12} className="align-self-start text-start pt-5 pl-0 pr-0">
                                <p >
                                    "We make a living by what we get. But we make a life by what we give"
                                </p>
                                <p>- Winston Churchill  </p>
                                <br/>
                                <br/>
                               
                                <Button className='btn btn-basic-org waves-effect m-r-10 button-text' >
                                    Become a fundraiser
                                </Button>
                            </Col>
                                </Row>
                            </Container>
                            </div>
                        </Col>
                        </Row>
            </div>
    );
}

export default BecameFundraiserBanner;
