/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Button, Card, CardBody } from 'reactstrap';
import Doctor from "../../assets/images/portfolio/Memorial_Portrait 1.png"
import img1 from '../../assets/images/portfolio/img1.jpg';
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NewsComponet from "./newsComponet"
import PropTypes from "prop-types";
import NewsArray from "../../assets/data/news-2.json";


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const newStories = () => {

    
    return (
        <div>
            <div className="simple-container">
                <Container>
                    <Row className="justify-content-center pb-3">
                        <Col md="7" className="align-self-start text-start">
                            <h2 className="title mt-4">
                            THE ROLE OF A&nbsp;<span style={{ color: "#ED0F0F" }}>VOLUNTEER&nbsp;</span>
                            </h2>
                            <h6 className="subtitle op-8 pt-4 pb-3">
                                HEART TO HEART TRUST FUND receives a donation of AUD (Australian Dollars) 94,820/=
                            </h6>
                            <h6 className="subtitle op-8 pt-4 pb-3">
                                ‘Mighty of Heart, mighty of mind, magnanimous – to be this, is indeed to be great in life’ sums up the life of Mauri Antoinette Clare Sendapperuma. It was indeed a blessing when we were informed that a Sri Lankan domiciled in Australia had left over AUD 94,820/= in her last will to be donated to the Heart To Heart Trust Fund. To us, who have been involved in the journey of the Trust Fund and experienced the joys and the sorrows of people who would give anything to have a second chance at life, Miss Sendapperuma’s generosity was like being touched by an angel.
                            </h6>
                            <Button href="/#/volunteers" className='btn-basic-org m-t-20 mb-4 font-15'>Join Us</Button>{' '}
                        </Col>
                        <Col/>
                        <Col md={4}>
                            <img src={Doctor} alt="Doctor" />
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <>
                        <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        >
                        
                        {NewsArray.map((item) => (
                            <NewsComponet key={item.id} newsimage={item.image} sourceLink={item.link} title={item.title} />
                        ))}
                        
                    </Carousel>
                        
                        
                        </>
                    </Row>
                    
                </Container>

            </div>
        </div>
    );
}

newStories.propTypes = {
    classes: PropTypes.object
};

export default newStories;
