/* eslint-disable */
import React, { useState, useEffec } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { collection, addDoc } from 'firebase/firestore';
import PaymentLogo from "../../assets/images/Dontaion/PaymentIcons.svg"
import { db } from '../../firebaseconfig';
import { postDoner } from '../../services/postDoner';


const DonationOnlineForm = () => {

    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState('');
    const [reason, setReason] = useState('');
    const [anonymous, setAnonymous] = useState(false);
    const sessionPayload = useSelector((state) => state.doner.payload);
    const loading = useSelector(state => state.doner.loading);
    const error = useSelector(state => state.doner.error);
    const [payloadValue, setPayloadValue] = useState('');

    const dispatch = useDispatch();
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const payload = {
                name: fullName,
                address: address,
                amount: amount,
                phone: phone,
                reason: reason,
                anonymous: anonymous
              };

            // dispatch(postDoner(payload));
            const response = await dispatch(postDoner(payload));

            
            console.log("payloadValue", "'"+ response.payload  + "'")
    
            // Clear form fields
            setFullName('');
            setAddress('');
            setAmount('');
            setPhone('');
            setReason('');
            setAnonymous(false);
    
            
            Checkout.configure({
                session: { 
                    id:  response.payload 

                },
            });
            Checkout.showPaymentPage()

        } catch (error) {
            console.log(error);
        }
    };

    return (
        
        <div style={{"paddingRight" : 67,"paddingLeft" : 67}}>
        <div id="forms-component" >
            <Container>
                <Row className="justify-content-center">
                    <Col md="7" className="text-center">
                        <h1 className="title font-bold donation-title">Donate Online</h1>
                        <hr/>
                    </Col>
                    <div className="logo-container">
                        <img src={PaymentLogo} alt="Bank Logo" className="logo-image" />
                    </div>
                </Row>
            </Container>
        </div>
        <Container>
            <Row>
                <Col md="12">
                    <Form className="donation-form row" >
                        <FormGroup className="col-md-12 custom-form-group">
                            <Label htmlFor="fullName">Full Name</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="fullName"
                                placeholder="Enter Your Full Name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-12 custom-form-group">
                            <Label htmlFor="address">Address</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="address"
                                placeholder="Enter Your Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-6 custom-form-group">
                            <Label htmlFor="phone">Phone</Label>
                            <Input
                                type="phone"
                                className="form-control"
                                id="phone"
                                placeholder="Enter your phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-6 custom-form-group">
                            <Label htmlFor="amount">Amount (LKR)</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="amount"
                                placeholder="Enter the Amount"
                                value={amount}
                                onChange={(e) => {
                                    const inputValue = e.target.value;

                                    // Validate the input to allow only numbers with up to two decimal places
                                    const validInput = /^\d+(\.\d{0,2})?$/.test(inputValue);

                                    if (validInput || inputValue === '') {
                                        setAmount(inputValue);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-12 custom-form-group">
                            <Label htmlFor="reason">Reason For Your Donation</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="reason"
                                placeholder="Ex : For My Birthday"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-12 ml-3 custom-form-group">
                            <Input
                                id="checkbox1"
                                type="checkbox"
                                checked={anonymous}
                                onChange={(e) => setAnonymous(e.target.checked)}
                            />
                            <Label htmlFor="checkbox1"> &nbsp;&nbsp;Keep Me Anonymous </Label>
                        </FormGroup>
                        {/* <Button type="submit" onClick={handleSubmit} className="btn btn-basic-org waves-effect m-r-10 col-md-12">
                            Donation
                        </Button> */}
                        <Button
                            type="button"
                            className="btn btn-basic-org waves-effect m-r-10 col-md-12"
                            onClick={handleSubmit}
                            // href="/#/underdevelopment"
                            disabled={
                                loading ||  
                                !fullName ||  
                                !address ||  
                                !phone || 
                                !amount || 
                                !reason  
                            }
                        >
                            {loading ? "Processing..." : "Pay with Payment Page"}
                        </Button>
                        {error && <p className="error-message">{error}</p>}

                        <div id='embed-target'>

                        </div>

                        

                    </Form>
                </Col>
            </Row>
        </Container>
    </div>
    );
}

export default DonationOnlineForm;
