import React from 'react';
import { Button, Card, CardBody, Container, Label } from 'reactstrap';
import calender from "../../assets/images/icons/uil_calender.svg";

const newsNStoriesComponet = ({newsimage, sourceLink, title, date, description}) => {
    return (
        <div>
            <Container>
            <Card className="card-shadow" style={{ height: "560px", display: "flex", flexDirection: "column" }}>
                <div className="">
                    <a className="img-news">
                        <img className="card-img-top" src={newsimage} alt={newsimage} />
                    </a>
                </div>
                <CardBody className="news-card" style={{ flex: "1" }}>
                    
                    <div className="">
                        <Label className="date-lable" style={{ textTransform: 'uppercase' }}>
                            <img src={calender} width={"20px"} />&nbsp;&nbsp;
                            <span style={{ paddingTop: '20px' }}>{date}</span>
                        </Label>
                        <br />
                        <Label className="news-title" style={{overflowWrap:'anywhere'}}>
                            {title.split(' ').slice(0, 14).join(' ')}
                            {title.split(' ').length > 14 ? '...' : ''}
                        </Label>
                        <Label className="news-content" style={{whiteSpace:'pre-wrap',}}>
                            {description.split(' ').slice(0, 25).join(' ')}
                            {description.split(' ').length > 25 ? '...' : ''}
                        </Label>
                    </div>
                </CardBody>
                <div style={{ marginLeft: '10px' }}>
                    <Button href={sourceLink} target="_blank" className='btn-basic-org mb-3 font-15 pl-5'>Details</Button>
                </div>
            </Card>
            </Container>
        </div>
    );
}

export default newsNStoriesComponet;
