/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import CommitteeTableRow from './committeTableRow';

const executiveCommittee = () => {
    return (
        <div>
            <div className='trustbanner-volunteers'>
                <Row>
                    <div className="executive-committee">
                        <Container className='align-self-center'>
                                <Row className="justify-content-start pl-0 pr-0">
                                    <Col md={12} className="align-self-start text-start pl-0 pr-0">
                                        <h2 className="title">
                                    HEART TO HEART &nbsp;<span style={{ color: "#ED0F0F" }}>VOLUNTEER&nbsp;</span> GROUP - EXECUTIVE COMMITTEE
                                        </h2>
                                    </Col>                                
                                </Row>
                        </Container>
                    </div>
                </Row>
                <Row className="row-cols-1 row-cols-md-2" style={{ paddingBottom: "67px" }}>
                <Col md="5">
                    <CommitteeTableRow title={"President"} name={"Dr.Rajitha De Silva (Ex Officio a Trustee)"} />
                    <CommitteeTableRow title={"National Organiser"} name={"Lt.Gen.(Retd.) Jagath Dias (Ex Officio a Trustee)"} />
                    <CommitteeTableRow title={"Asst.Secretary"} name={"Kareem Amath"} />
                    <CommitteeTableRow title={"Asst. Treasuer"} name={"Lion Premanath De Zoysa"} />
                    <CommitteeTableRow title={"Media Coordinators"} 
                    name={[
                        "Zanita Careem",
                        <br />,
                        "\u00A0\u00A0", 
                        "Dilantha De Silva",
                        <br />,
                        "\u00A0\u00A0", 
                        "Indika Pradeep"
                    ]}/>     
                        <CommitteeTableRow title={"Admin Coordinator"} 
                    name={[
                        "Sister Champa Samarasinghe",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Sister Shirani Edirisinghe"
                    ]}/>    
                         <CommitteeTableRow title={"Social Service Coordinators"} 
                    name={[
                        "Matron Kala Karunaratne",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Sister Nandani Liyanage"
                    ]}/>     
                    <CommitteeTableRow title={"Cardiac Club Coordinator"} name={"Dian Abeywardena"} />
                    <CommitteeTableRow title={"Pinkalaeththo coordinator"} name={"Indika Pradeep"} />
                    </Col>
                    <Col md="2">
                    </Col>



<Col md="5" >
                    <CommitteeTableRow title={"Vice President"} name={["Arunashantha De Silva PC (Ex Officio a Trustee)"
                    , <br />,
                        "\u00A0\u00A0",  "Lion G I Sumanasena"]} />
                    <CommitteeTableRow title={"Secretary"} name={"Shanti Fernando(Ex Officio)"} />
                    <CommitteeTableRow title={"Treasure"} name={"W V A R S Weragoda"} />
                    <CommitteeTableRow title={"Executive Committee"} 
                    name={[
                        "Sarah Wimaralaratna",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Mohan Wickramanayaka",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Brig.(Retd).Kunban Bohoran",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Sharmeen Amath",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Dr.Pasan Gamage",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Kiran Dhanapala",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Lakshmi Perera",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "S H Duncan Silva",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "D G Premanda",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "Prasan Wanithunga",
                        <br />,
                        "\u00A0\u00A0\u00A0", 
                        "T.P. Bohoran"
                    ]}/> 

                    </Col>

                    </Row>
                       
             


              
            </div>    
        </div>
    );
}

export default executiveCommittee;
