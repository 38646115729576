import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

const HeaderBanner = ({heading}) => {
    return (
        <div className="static-slider-head">
            <Container>
                <Row className="justify-content-start">
                    <Col lg="7" md="6"/>
                    <Col lg="5" md="6" className="align-self-start text-start">
                        <h1 className="title">{heading}<span style={{color:"red"}}>.</span></h1>
                        <h4 className="subtitle font-light align-self-center text-start">Lend a helping hand </h4>
                        <Button href={"/#/donation"} className='btn-basic-org m-t-20  font-15'>Donate</Button>{' '}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderBanner;
