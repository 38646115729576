// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkoDGpA39C0IMr5DZLUrARL-_uO27ihUY",
  authDomain: "heart2heart-3f636.firebaseapp.com",
  projectId: "heart2heart-3f636",
  storageBucket: "heart2heart-3f636.appspot.com",
  messagingSenderId: "120582235426",
  appId: "1:120582235426:web:8bfb125aa8fcf19c62b17b",
  measurementId: "G-QTZ368F827"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore()

// addDoc(collection(db,'donors'),{
//     name:'R
// }).then(id=>{})

// getDocs(collection(db,'donors'))

// getDocs(query(collection(db,'donors'),where('name','==','raj'))).then(snapshot=>{
//     snapshot.docs.
// })