/* eslint-disable */
import PropTypes from "prop-types";
import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Row } from 'reactstrap';
import DonationArray from "../../assets/data/donationsAbhaya.json";
import AbhayadanaComponet from "./abhayadanaComponet";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const abhayadhanaList = () => {

    
    return (
        <div>
            <div className="simple-container">
                <Container>
                    <Row className='pt-5'>
                        <>
                        <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        responsive={responsive}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        >
                        
                        {DonationArray.map((item) => (
                            <AbhayadanaComponet 
                            key={item.id} 
                            abhayadhanaimage={item.image} 
                            sourceLink={item.link} 
                            title={item.title} />
                        ))}
                        
                    </Carousel>
                        
                        
                        </>
                    </Row>
                    
                </Container>

            </div>
        </div>
    );
}

abhayadhanaList.propTypes = {
    classes: PropTypes.object
};

export default abhayadhanaList;
