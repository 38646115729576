/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Button, Card, CardBody, Label } from 'reactstrap';
import Doctor from "../../assets/images/portfolio/Memorial_Portrait 1.png"
import img1 from '../../assets/images/portfolio/img1.jpg';
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DonationData from "../../assets/data/donations.json"
import CarouselCard from './CarouselCard';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const DonationCarousel = () => {
    return (
        <div>
            <div className="simple-container">
                <Container>
                    <h2 className="title mt-1 mb-5"> Donations</h2>
                   
                    <Row className=''>
                        <>
                        <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        >
                          {DonationData.map((item, index) => (
                        <CarouselCard  key={index} title={item.title} description={item.description} image={item.image} link={item.link}/>
                    ))}
                    </Carousel>  
                        </>
                    </Row>    
                </Container>

            </div>
        </div>
    );
}

export default DonationCarousel;
