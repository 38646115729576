import PropTypes from "prop-types";
import React from "react";

// core components
import { Col, Container, Row } from 'reactstrap';
import HeaderBanner from "../../components/banner/banner.jsx";
import Footer from "../../components/footer/footer.jsx";
import Header from "../../components/header/header.jsx";


import BankTransferCards from "../../components/Donation/BankTransferCards.jsx";
import BecameFundraiserBanner from "../../components/Donation/BecameFundraiserBanner.jsx";
import DonationCarousel from "../../components/Donation/DonationCarousel.jsx";
import DonationOnlineForm from "../../components/Donation/DonationOnlineForm.jsx";

const DonationPage = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner heading="Donate to save life"/>
                    <Row >
                        <Col md={6}  style={{backgroundColor:"#272A36"}}>
                            <BecameFundraiserBanner/>
                        </Col>
                        <Col md={6} >
                            <DonationOnlineForm />
                        </Col>
                    </Row>
                    <BankTransferCards/>
                    <div className='donation-banner'>
                        <Row className="justify-content-center pl-0 pr-0">
                            <Col md={12} className="align-self-center text-center">
                                <div className="gray-container-donation">
                                <Container className='quote-text'>
                                    <Row className="justify-content-start pl-0 pr-0">
                                    <Col md={12} className="align-self-start text-start donation-box-3 donation-description">
                                        Our lifeblood derives from the generosity of the above donors and equally from the scores of other citizens who through the generosity of their hearts and their deep felt humanity and in accordance with their affordability remit to our account, often anonymously and sometimes regularly each month or year, various contributions big and small to help save the lives of heart patients needing cardiac surgery but unable to afford the cost.
                                    </Col>
                                        </Row>
                                    </Container>
                                    </div>
                                </Col>
                        </Row>

                    </div>

                    <DonationCarousel />

                    {/* <Provider store={store}>
                        <DonerTable/>
                    </Provider> */}
                    
                </div>
            </div>
            
            <Footer />
        </div>
        
    );
}

DonationPage.propTypes = {
    classes: PropTypes.object
};

export default DonationPage;
