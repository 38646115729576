import React from 'react';
import { Card, Col, Container, Label, Row, Table } from 'reactstrap';

const bannerTrustFund = () => {
    // Data for the table rows
    const dataRows = [
        { financialYear: '2014-2015', beneficiaries: 3, fundsSpent: 350000 },
        { financialYear: '2015-2016', beneficiaries: 21, fundsSpent: 2337000 },
        { financialYear: '2016-2017', beneficiaries: 46, fundsSpent: 5287497 },
        { financialYear: '2017-2018', beneficiaries: 48, fundsSpent: 5238236 },
        { financialYear: '2018-2019', beneficiaries: 56, fundsSpent: 6777524 },
        { financialYear: '2019-2020', beneficiaries: 96, fundsSpent: 10758871 },
        { financialYear: '2020-2021', beneficiaries: 41, fundsSpent: 4595546 },
        { financialYear: '2021-2022', beneficiaries: 88, fundsSpent: 11275663 },
        { financialYear: '2022-2023', beneficiaries: 104, fundsSpent: 18259680 },
        { financialYear: '2023-2024', beneficiaries: 112, fundsSpent: 17930391 },
        
        
        // Add more rows as needed
    ];

    // Calculate total values
    const totalBeneficiaries = dataRows.reduce((total, row) => total + row.beneficiaries, 0);
    const totalFundsSpent = dataRows.reduce((total, row) => total + row.fundsSpent, 0);

    return (
        <div>
            <div className="pt-3">
                <Container>
                    <Row className="justify-content-center text-center progreport-content">
                        <Label className='title' >PROGRESS REPORT</Label>
                        <Label className='subtitle' >2014 December 01 TO 2024 may 31</Label>

                        <Col className='pt-4'>
                            <Card body className="custom-card-body pb-5 pt-3">
                            <Table className="table table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>Financial Year</th>
                                        <th>No. of Beneficiaries</th>
                                        <th>Total funds spent on patients</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataRows.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.financialYear}</td>
                                            <td>{row.beneficiaries.toLocaleString()}</td>
                                            <td>{row.fundsSpent.toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr className="divider-row">
                                        <td colSpan="3"></td>
                                    </tr>
                                    <tr className="total-row">
                                        <th>Total</th>
                                        <th>{totalBeneficiaries.toLocaleString()}</th>
                                        <th>{totalFundsSpent.toLocaleString()}</th>
                                    </tr>
                                </tfoot>
                            </Table>

                                
                            </Card>
                        </Col>

                    </Row>
                    <Label className='simple-content' >In 2021, the number of surgeries was less due to the COVID-19 pandemic. 104 surgeries were performed to which our contribution amounted to Rs. 18,259,680/= during thfinancial year 2022/2023. And 101 surgeries were performed to which our contribution amounted to Rs. 16,626,127/= during the financial year 2023/2024.</Label>

                </Container>
            </div>
        </div>
    );
}

export default bannerTrustFund;
