/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Location from "../../assets/images/icons/mdi_address-marker.svg"
import Email from "../../assets/images/icons/ic_baseline-mail.svg"
import Phone from "../../assets/images/icons/icomoon-free_mobile.svg"
import Call from "../../assets/images/icons/fluent_call-16-filled.svg"
import hthLogo from '../../assets/images/logos/hthlogo.png';


const Footer = () => {
    return (
        <div className="footer4 b-t pt-5 pb-3">
            <Container>
                <div>
                    <img src={hthLogo} alt="wrapkit" style={{  width: '90px', height: '90px' }} />
                </div>
                <Row>

                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="title m-b-20">Heart to Heart <br/>Trust Fund</h5>
                        <p>Trust Fund is wholly and entirely a Charitable fund. We depend 100% on donations and we plan to perform free heart surgeries on patients.</p>
                    </Col>
                    <Col lg="3" md="6" className=" m-b-30 ">
                        <h5 className="subtitle m-b-20">Quick Contact</h5>
                        <p className='subtitletext'><img src={Email}/>&nbsp;&nbsp;heart2hearttrustfund@gmail.com</p>
                        <p className='subtitletext'><img src={Phone}/>&nbsp;&nbsp;0777344410</p>
                        <p className='subtitletext'><img src={Phone}/>&nbsp;&nbsp;0718244601</p>
                        <p className='subtitletext'><img src={Call}/>&nbsp;&nbsp;0112778610</p>
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="subtitle m-b-20">Address</h5>
                        <p className='subtitletext'><img src={Location}/>&nbsp;Sri Jayawardenapura General Hospital, Thalapathpitiya, Nugegoda</p>
                    </Col>
                    <Col lg="3" md="6">
                        <h5 className="subtitle m-b-20">Stay connected</h5>
                        <div className="round-social light">
                            <a href="https://www.facebook.com/heart2hearttrust" className="link"><i className="fa fa-facebook"></i></a>
                            <a href="https://twitter.com/heart2heartSL" className="link"><i className="fa fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/company/heart-to-heart-trust-fund/about/" className="link" style={{fontWeight:"700"}}>in</a>
                            <a href="https://www.youtube.com/channel/UChCP0uH_14vOoKkCucL11xw" className="link"><i className="fa fa-youtube-play"></i></a>
                        </div>
                    </Col>
                </Row>
                <div className="f4-bottom-bar">
                    <Row>
                        <Col md="12" className="font-14 text-end">
                            <div className="links ms-auto m-t-10 ">All Rights Reserved by WrapPixel.</div>
                            <div className="links ms-auto m-t-10 ">
                            <a href="http://amerck.com/" className="p-10 p-l-0">AMERCK</a>
                            <span className="p-10">| Noble tech partner</span> {/* Changed <lable> to <span> */}
                            </div>
                        </Col>
                    </Row>

                </div>
            </Container>
        </div>
    );
}
export default Footer;
