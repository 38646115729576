/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Button, Card, CardBody } from 'reactstrap';
import Doctor from "../../assets/images/portfolio/Memorial_Portrait 1.png"
import img1 from '../../assets/images/portfolio/img1.jpg';
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NewsNStoriesComponet from "./newsNStoriesComponet"
import PropTypes from "prop-types";
import AllNewsStory2024Array from "../../assets/data/news2024.json";
import AllNewsStory2023Array from "../../assets/data/news2023.json";
import AllNewsStory2022Array from "../../assets/data/news2022.json";
import AllNewsStory2021Array from "../../assets/data/news2021.json";
import AllNewsStory2020Array from "../../assets/data/news2020.json";
import AllNewsStory2019Array from "../../assets/data/news2019.json";
import AllNewsStory2018Array from "../../assets/data/news2018.json";
import AllNewsStory2015Array from "../../assets/data/news2015.json";


const newsList = () => {

    return (
        <div>
            <div className="simple-container">
                <Container>
                <h1>2024</h1>
                    <Row className='pt-5'>
                        {AllNewsStory2024Array.map((item) => (
                            <Col md={6} lg={4} key={item.id}>
                                <NewsNStoriesComponet newsimage={item.image} description={item.description} date={item.date} sourceLink={item.link} title={item.title} />
                            </Col> 
                        ))}
                    </Row>

                    <h1>2023</h1>
                    <Row className='pt-5'>
                        {AllNewsStory2023Array.map((item) => (
                            <Col md={6} lg={4} key={item.id}>
                                <NewsNStoriesComponet newsimage={item.image} description={item.description} date={item.date} sourceLink={item.link} title={item.title} />
                            </Col> 
                        ))}
                    </Row>

                    <h1>2022</h1>
                    <Row className='pt-5'>
                        {AllNewsStory2022Array.map((item) => (
                            <Col md={6} lg={4} key={item.id}>
                                <NewsNStoriesComponet newsimage={item.image} description={item.description} date={item.date} sourceLink={item.link} title={item.title} />
                            </Col> 
                        ))}
                    </Row>

                    <h1>2021</h1>
                    <Row className='pt-5'>
                        {AllNewsStory2021Array.map((item) => (
                            <Col md={6} lg={4} key={item.id}>
                                <NewsNStoriesComponet newsimage={item.image} description={item.description} date={item.date} sourceLink={item.link} title={item.title} />
                            </Col> 
                        ))}
                    </Row>

                    <h1>2020</h1>
                    <Row className='pt-5'>
                        {AllNewsStory2020Array.map((item) => (
                            <Col md={6} lg={4} key={item.id}>
                                <NewsNStoriesComponet newsimage={item.image} description={item.description} date={item.date} sourceLink={item.link} title={item.title} />
                            </Col> 
                        ))}
                    </Row>

                    <h1>2019</h1>
                    <Row className='pt-5'>
                        {AllNewsStory2019Array.map((item) => (
                            <Col md={6} lg={4} key={item.id}>
                                <NewsNStoriesComponet newsimage={item.image} description={item.description} date={item.date} sourceLink={item.link} title={item.title} />
                            </Col> 
                        ))}
                    </Row>

                    <h1>2018</h1>
                    <Row className='pt-5'>
                        {AllNewsStory2018Array.map((item) => (
                            <Col md={6} lg={4} key={item.id}>
                                <NewsNStoriesComponet newsimage={item.image} description={item.description} date={item.date} sourceLink={item.link} title={item.title} />
                            </Col> 
                        ))}
                    </Row>

                    <h1>2015</h1>
                    <Row className='pt-5'>
                        {AllNewsStory2015Array.map((item) => (
                            <Col md={6} lg={4} key={item.id}>
                                <NewsNStoriesComponet newsimage={item.image} description={item.description} date={item.date} sourceLink={item.link} title={item.title} />
                            </Col> 
                        ))}
                    </Row>


                    
                </Container>

            </div>
        </div>
    );
}

newsList.propTypes = {
    classes: PropTypes.object
};

export default newsList;
