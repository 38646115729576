import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./ChartsDynamicColor";
import data from "../../../assets/data/Progress/surgery.json"

const Apaexlinecolumn = ({dataColors}) => {
  const apaexlineColumnColors = getChartColorsArray(dataColors);

  // Extract the years and surgerytypes from the data
  const years = ["2014/2022", "2022/2023", "2023/2024"];
  const surgerytypes = data.map(item => item.surgerytype);

  // Organize the data for the chart
  const series = surgerytypes.map(surgerytype => {
    return {
      name: surgerytype,
      data: years.map(year => data.find(item => item.surgerytype === surgerytype)[year] || 0),
    };
  });

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: apaexlineColumnColors,
    xaxis: {
      categories: years,
    },
    yaxis: {
      title: {
        text: "Value",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default Apaexlinecolumn;
