import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api.js';

export const postMessage = createAsyncThunk(
  '/sendContactEmail',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/sendContactEmail',
        payload,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      const data = response.data;
      if (data['error']) {
        return rejectWithValue(data['payload']); // Reject with error message
      }
      return data['payload']; // Return the payload value
    } catch (error) {
      return rejectWithValue(error.message); // Reject with error message
    }
  }
);
