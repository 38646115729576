import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Modal } from "antd";
import React, { useState } from "react";
import PDF from "react-pdf-js";
import useDeviceDetection from "../../hooks/useDeviceDetection";
const PdfViewer = ({ pdf, onCancel, visible }) => {

  const [myPdf, setMyPdf] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [scale, setScale] = useState(1);

  const device = useDeviceDetection();

  const onDocumentComplete = (numPages) => {
    setPages(numPages)
  }

  const onDocumentError = (err) => {
    console.error('pdf viewer error:', err);
  }

  const onSetScale = (type) => {

    var newScale = type ? scale + 0.1 : scale - 0.1;
    if (device == "Mobile") {
      setScale(3)
    } else {



      if (newScale > 2) {
        newScale = 2
      } else if (newScale < 0.1) {
        newScale = 0.1
      }
    }
    setScale(newScale)

  }

  const onPage = (type) => {

    var newPage = type ? page + 1 : page - 1
    if (newPage > pages) {
      newPage = 1
    } else if (newPage < 1) {
      newPage = pages
    }

    setPage(newPage)
  }

  const zoomStyle = {
    marginLeft: 10,
    cursor: 'pointer'
  }

  const footer = <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    <Button onClick={() => onPage(0)}>Previous</Button>
    <div>
      <span style={{ textAlign: 'center' }}>Page {page} of {pages}</span>
      <ZoomOutOutlined style={{ ...zoomStyle, opacity: scale === 0.1 ? 0.5 : 1 }} onClick={() => onSetScale(0)} />
      <ZoomInOutlined style={{ ...zoomStyle, opacity: scale === 2 ? 0.5 : 1 }} onClick={() => onSetScale(1)} />
      <span>{Math.round(scale * 100)}%</span>
    </div>
    <Button onClick={() => onPage(1)}>Next</Button>
  </div>

  return (


    <Modal maskClosable={false}
      onCancel={onCancel}
      visible={visible}
      width={device == 'Desktop' ? "60%" : "50"}
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      style={{ top: 30 }}
      footer={footer}

    >
      <div className="pdfWrapper text-center">
        <PDF
          file={pdf}
          onDocumentComplete={onDocumentComplete}
          onDocumentError={onDocumentError}
          page={page}
          scale={device == 'Mobile'?0.6:scale}
        />
      </div>
    </Modal>)

};
export default PdfViewer;