import { Col, Row } from 'reactstrap';
import Email from "../../assets/images/Contact/email.svg";
import Location from "../../assets/images/Contact/location.svg";
import Phone from "../../assets/images/Contact/phone.svg";
import DetailCard from './DetailCard';


const Details = ({icon,title,data}) => {
return(
    <div className='details'>
<Row>
    <Col>
    <DetailCard icon={Email} title="Email" data="heart2hearttrustfund@gmail.com"/>
    </Col>

    <Col>
    <DetailCard 
    icon={Phone}
    title="Call Us" 
    data={[ "\u00A0\u00A0", "Mrs. Shanti Fernando: +94 77 734 4410",
                        <br />,
                        "\u00A0\u00A0", 
                        "Mrs. Shirani Edirisingha: +94 71 824 4645",
                        <br />,
                        "\u00A0\u00A0", 
                        "Mrs. Champa Samarasinghe: +94 71 824 4601",
                        <br />,
                        "\u00A0\u00A0", 
                        "Sri Jayawardenapura: +94 11 277 8610",
                        <br />,
                        "\u00A0\u00A0", 
                        "Ext. 3286 General Hospital",

                    ]}/>
    </Col>

    <Col>
    <DetailCard icon={Location} title="Address" data="Sri Jayawardenapura General Hospital,Thalapathpitiya,Nugegoda,Sri Lanka."/>
    </Col>
</Row>
        
</div>
)
}

export default Details;
