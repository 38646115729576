import { Card, CardBody } from 'reactstrap';


const DetailCard = ({icon,title,data}) => {
return(
    <Card className="contact-card">
    <CardBody className="card-body">
        <div className="icon-container">
            <img className="icon" src={icon} alt="Donation" />
        </div>
        <div className="content-container">
            <h5 className="font-medium contact-title">{title}</h5>
            <p className="font-14 contact-description">{data}</p>
        </div>
    </CardBody>
</Card>
)
}

export default DetailCard;
