/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Container, Button, Label,Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'; // Import Modal
import OnlineDonation from "../../assets/images/icons/make-an-online-donation.png"
import Insurence from "../../assets/images/icons/property-insurance.png"
import ShakingHand from "../../assets/images/icons/shaking-hands-inside-a-heart.png"
import H2HFile from "../../assets/images/H2HFundRequestApplicationForm.pdf"
import Select from 'react-select';
import { db } from '../../firebaseconfig';
import { collection, addDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { postVoluntee } from '../../services/postVoluntee';

const bannerTrustFund = () => {
    const [showFullContent, setShowFullContent] = useState(false);
    const [showJoinUsPopup, setShowJoinUsPopup] = useState(false); // State for the Join Us popup
    
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [area, setArea] = useState('');
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();


    const areAllFieldsFilled = () => {
        return (
            fullName !== '' &&
            email !== '' &&
            dob !== '' &&
            phone !== '' &&
            gender !== '' &&
            area !== '' &&
            message !== ''
        );
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Initial step")
        try {
            const payload = {
                name: fullName,
                email: email,
                dob: dob,
                phone: phone,
                gender: gender,
                area: area,
                message: message
            };
            console.log("After payload step")
            dispatch(postVoluntee(payload));
    
            // Display a success alert
            await Swal.fire({
                icon: 'success',
                title: 'Thank you!',
                text: 'Your volunteer application has been submitted.',
            });
    
            // Clear the form after submission
            setFullName('');
            setEmail('');
            setDob('');
            setPhone('');
            setGender('');
            setArea('');
            setMessage('');
    
            // Close the popup
            toggleJoinUsPopup();
    
        } catch (error) {
            console.log(error);
            // Display an error alert
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong. Please try again later.',
            });
        }
    };
    
    const toggleJoinUsPopup = () => {
        setShowJoinUsPopup(!showJoinUsPopup);
        setFullName('');
        setEmail('');
        setDob('');
        setPhone('');
        setGender('');
        setArea('');
        setMessage('');
    };

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };

    

    const volunteerAreaOptions = [
        { value: 'Befriending & Mentoring', label: 'Befriending & Mentoring' },
        { value: 'Administrative / Office Work', label: 'Administrative / Office Work' },
        { value: 'Arts (Music / Drama / Crafts)', label: 'Arts (Music / Drama / Crafts)' },
        { value: 'Teaching / Tutoring / Supporting Learning', label: 'Teaching / Tutoring / Supporting Learning' },
        { value: 'Counselling / Listening', label: 'Counselling / Listening' },
        { value: 'Youth Work', label: 'Youth Work' },
        { value: 'Events / Stewarding', label: 'Events / Stewarding' },
        { value: 'Sports / Outdoor Activities / Coaching', label: 'Sports / Outdoor Activities / Coaching' },
        { value: 'Other - (Please specify on message)', label: 'Other - (Please specify on message)' },
    ];

    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
    ];

    

      
    return (
        <div>
            <div className="pt-3">
                <Container>
                    <Row className=" catagory-content">
                        <Col md={4} sm={6} className="text-control pt-5 pb-5 ">
                           <Row>
                                <Col md="4" className="text-center">
                                    <img src={ShakingHand} alt="Online Donation" />
                                </Col>
                                <Col md="8" className='pt-1'>
                                    <Label style={{fontWeight: '700'}}>BECOME A VOLUNTEER</Label>
                                    <Label >Join The Heart To Heart Trust Fund To Help Achieve Our Objectives.</Label>
                                    <Button onClick={toggleJoinUsPopup} className='btn-border-org m-t-20  font-15'>Join Us</Button>{' '}
                                </Col>
                           </Row>
                        </Col>
                        <Col md={4} sm={6} className="text-control pt-5 pb-5 ">
                           <Row>
                                <Col md="4" className="text-center"><img src={Insurence} alt="Online Donation" /></Col>
                                <Col md="8" className='pt-1 '>
                                    <Label style={{fontWeight: '700'}}>SAVE A LIFE</Label>
                                    <Label >Sponsor a heart surgery and save a life. “Abahyadana” programme.</Label>
                                    <Button href="/#/donation" className='btn-border-org m-t-20  font-15'>Donate</Button>{' '}
                                </Col>
                           </Row>
                        </Col>
                        <Col md={4} sm={6} className="text-control pt-5 pb-5">
                           <Row>
                                <Col md="4" className="text-center"><img src={OnlineDonation} alt="Online Donation" /></Col>
                                <Col md="8" className='pt-1'>
                                    <Label style={{fontWeight: '700'}}>NEED SUPPORT  </Label>
                                    <Label >Download the form below and email to "heart2hearttrustfund@gmail.com"</Label>
                                    <Button href={H2HFile} className='btn-border-org m-t-20  font-15'>Download</Button>{' '}
                                </Col>
                           </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='trustbanner'>
                <Row className="justify-content-center pl-0 pr-0">
                    <Col md={showFullContent ? 12 : 6} className="align-self-center text-center">
                        <div className="gray-container">
                        <Container>
                            <Row className=" pl-0 pr-0">
                                <Col md={12} className="align-self-start text-start pl-0 pr-0">
                                    <h2 className="title">
                                    WHY&nbsp;<span style={{ color: "#ED0F0F" }}>HEART TO HEART&nbsp;</span>
                                    </h2>
                                    <h2 className="title">TRUST FUND </h2>
                                    <h6 className="subtitle op-8 pt-3">
                                    The Heart to Heart Trust Fund is a charitable fund.The Heart To Heart Trust
                                    Fund is a beacon of hope for financially impoverished heart patients across the
                                    country. When treatment though cardiac surgery is beyond their financial means,
                                    the Fund revives their hopes by providing financial assistance to impoverished
                                    heart patients to help save their lives and brighten their future.
                                    </h6>
                                    {showFullContent && (
                                    <p>
                                        The Heart to Heart Trust Fund is a charitable fund.The Heart To Heart Trust Fund is a beacon of hope for financially impoverished heart patients across the country. When treatment though cardiac surgery is beyond their financial means, the Fund revives their hopes by providing financial assistance to impoverished heart patients to help save their lives and brighten their future.

                                        Our problem in Sri Lanka is the long waiting list for cardiac surgical intervention in the non-fee-levying Government hospitals – often one year or more at the NHSL and two years or more in Kandy, Galle and Jaffna. The long waiting list in the Government sector for cardiac surgery where timely surgical intervention is of the essence is the outcome of a combination of three factors – an inadequate number of ICU beds and operating theatres for cardiac surgeons and catheterization laboratories (Cath Labs) for cardiologists to perform percutaneous interventions such as stent placement (stenting), placement of artificial pacemakers and a highly inadequate number of cardiothoracic surgeons – a mere 13 in the whole Government sector. In Australia, which has a population similar to that of Sri Lanka, there are about 200 qualified cardiac surgeons while of course in many developing countries there are none at all.

                                        Hence, when a long date is given to a heart patient where fast and timely intervention is of the essence to win the battle between life and death, the only available option then is to seek treatment in a fee levying hospital. In such in­stances, the cost to the patient – depending on the technique used in the case of surgery (key­hole/off pump/on pump) and post operative clinical complications if any - could roughly be anything between 550,000 rupees and 900,000 rupees in a private hospital, the comparative cost to the patient in the fee-levying semi-Govern­ment Sri Jayewadenepura General Hospital (SJGH) could be lower - varying between 350,000 rupees and 550,000 rupees on average in most cases.

                                        The better off patients then naturally go to the private healthcare sector which has seven cardiac surgeons (including Indian surgeons) working full-time in addition to the Government sector cardiac surgeons who work in private hospitals in their non working hours after 4pm. To settle for this option and save their lives, fi­nancially impoverished patients can access as­sistance amounting to 200,000 rupees from the President’s Fund after going through a long and tiresome application procedure.

                                        And this is where the Heart to Heart Trust Fund comes in. We provide financial support of another 150,000 rupees to 200,000 rupees and sometimes even more to patients who are sup­ported by the President’s Fund as this will en­able them to mobilize where possible the required balance from their own meagre resources.
                                    </p>
                                    )}
                                    <Button onClick={toggleContent} className='pt-3' style={{ background: 'none', border: 'none', padding: 0, color: '#646464', fontSize: "px", fontWeight: '700' }}>
                                        {showFullContent ? "See Less" : "See More..."}
                                    </Button>

                                </Col>
                                <Row className='justify-content-center ' style={{paddingLeft:"150px"}}>
                                <span style={{paddingRight:"222px", marginTop:"50px", fontSize:"24px", color:"white"}}>From 2014 to 2023</span>
                                <Row style={{marginTop:"-50px", justifyContent:"center"}} >   
                                    <Col md={6}  className="align-self-start text-start trust-values">
                                            <lable style={{color:'rgb(237, 15, 15)'}} className="large-vlaue">590 </lable><br/>
                                            <lable className="vlalue-text">Lives Saved </lable>
                                        </Col>
                                        <Col md={6}  className="align-self-start text-start trust-values">
                                            <lable style={{color:'rgb(237, 15, 15)'}}className="large-vlaue">80.0 M</lable><br/>
                                            <lable className="vlalue-text">Funds Spent </lable><br/>
                                            {/* <lable style={{fontSize:'14px',color:'red'}} className="vlalue-text">Financial Year:2014 - 2023</lable> */}
                                        </Col>
                                    </Row>
                                    </Row>
                                </Row>
                            </Container>
                            </div>
                        </Col>
                        {!showFullContent && (
                            <Col>
                            <div className="static-sliderpatient"></div>
                            </Col>
                        )}
                        </Row>

                        <Modal isOpen={showJoinUsPopup} toggle={toggleJoinUsPopup} size="lg">
                            <ModalHeader toggle={toggleJoinUsPopup}  >Become a Volunteer</ModalHeader>
                            <ModalBody>
                                <div>
                                    <Row>
                                        <Col md={1}/>
                                        <Col md={10}>
                                        <Row>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Full Name *</Label>
                                                <Input
                                                    className="vol-input"
                                                    placeholder="Enter Full Name"
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Email *</Label>
                                                <Input
                                                    className="vol-input"
                                                    placeholder="Enter Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Date of Birth *</Label>
                                                <Input
                                                    type="date"
                                                    className="vol-input"
                                                    placeholder="Enter Date of Birth"
                                                    value={dob}
                                                    onChange={(e) => setDob(e.target.value)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Mobile Number *</Label>
                                                <Input
                                                    className="vol-input"
                                                    placeholder="Enter Mobile Number"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Gender *</Label>
                                                <Select
                                                    options={genderOptions}
                                                    className="vol-input"
                                                    placeholder="Select Gender"
                                                    value={gender}
                                                    onChange={(selectedOption) => setGender(selectedOption)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Volunteer Area *</Label>
                                                <Select
                                                    options={volunteerAreaOptions}
                                                    className="vol-input"
                                                    placeholder="Select Volunteer Area"
                                                    value={area}
                                                    onChange={(selectedOption) => setArea(selectedOption)}
                                                />
                                            </Col>
                                            <Col md={12} className="pt-3">
                                                <Label className="vol-lable">Message *</Label>
                                                <Input
                                                    type="textarea"
                                                    className="vol-text-input"
                                                    placeholder="Write your message..."
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                            
                                        </Col>
                                        <Col md={1}/>
                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Col md={12} className="text-center">
                                    <Button onClick={handleSubmit} disabled={!areAllFieldsFilled()} className='btn-border-apply-org m-t-20 font-15' style={{ width: '100%' }}>
                                        Join Us
                                    </Button>
                                </Col>
                            </ModalFooter>

                        </Modal>
            </div>
            
        </div>
    );
}

export default bannerTrustFund;
