import PropTypes from "prop-types";
import React from "react";


// core components
import BannerTrustFund from "../../components/Volunteers/bannerTrustFund";
import ExecutiveCommittee from "../../components/Volunteers/executiveCommittee";
import HowToBecameVolBanner from "../../components/Volunteers/howToBecameVolBanner";
import HeaderBanner from "../../components/banner/HeaderBanner";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";



const ContactPage = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner heading="Volunteers" />

                    {/* <Location/> */}

                    <BannerTrustFund/>

                    <div style={{ paddingLeft :'130px', paddingRight:'130px' }}>
                    <HowToBecameVolBanner/>

                    <ExecutiveCommittee/>

                    </div>


                                         
                </div>
            </div>
            <Footer />
        </div>
    );
}

ContactPage.propTypes = {
    classes: PropTypes.object
};

export default ContactPage;
