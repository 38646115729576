import React from 'react';
import { Card, CardText, CardTitle, Container } from 'reactstrap';



const trusteeComponet = ({name, title, area, positon }) => {
    return (
        <div>
            <Container>
                <Card body className="card-shadow card-bg align-self-center text-center pt-4 pb-4" style={{height:"140px"}}>
                    <CardTitle className='title'>{name}</CardTitle>
                    <CardText className='subtitle' >{title}<br/> <span>{area}</span> <br/> <span>{positon}</span></CardText>
                </Card>
            </Container>
        </div>
    );
}

export default trusteeComponet;
