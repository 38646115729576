import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "./api.js"

export const getDoners = createAsyncThunk(
  '/getDonors',
  async () => {
    try {
      const response = await api.get("/getDonors" );
      const data = response.data;
      console.log(data); // Log the response data
      if (data.error) {
        throw new Error(data.payload);
      }
      return data.payload;
    } catch (error) {
      console.error(error); // Log any errors
      throw error;
    }
  }
);
