import PropTypes from "prop-types";
import React from "react";


// core components
import AbhayadhanaList from "../../components/Abhayadhana/abhayadhanaList";
import BannerAbhayaProgram from "../../components/Abhayadhana/bannerAbhayaProgram";
import HeaderBanner from "../../components/banner/banner";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";





const HomePage = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner heading="Abhayadhana"/>

                    <BannerAbhayaProgram/>

                    <AbhayadhanaList/>
                    
                </div>
            </div>
            <Footer />
        </div>
    );
}

HomePage.propTypes = {
    classes: PropTypes.object
};

export default HomePage;
