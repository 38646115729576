import React from 'react';
import { Col, Container, Label, Row, Table } from 'reactstrap';
import data from "../../assets/data/Progress/employment.json";

const employmentBreakdown = () => {
    const calculateColumnTotal = (columnName) => {
        return data.reduce((total, item) => total + (item[columnName] || 0), 0);
      };

    return (
        <div className="pt-5">
            <div className="pt-3">
                <Container>
                    <Row className="justify-content-center text-center progreport-content">
                        <Label className='title pb-3' >BREAKDOWN OF PATIENTS AIDED BY EMPLOYMENT</Label>
                        <Col md={1} />
                        {/* <Col  md={10}><img src={EmployementChart}  /></Col> */}
                        <Col  md={10}>
                            <Row>
                                <Col md={12}>
                                <div className='table-responsive'>
                                    <Table className="table mb-0">
                                        <thead className="table-light">
                                            <tr>
                                            <th>Employment Category</th>
                                            <th>2014/2022</th>
                                            <th>2022/2023</th>
                                            <th>2023/2024</th>
                                            {/* <th>2022/2023</th> */}
                                            <th>Total</th>
                                            <th>%</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <td className="bold-column">{item.employmentcategory}</td>
                                                <td>{item['2014/2022']}</td>
                                                <td>{item['2022/2023']}</td>
                                                <td>{item['2023/2024']}</td>
                                                {/* <td>{item['2022/2023']}</td> */}
                                                <td>{item.Total}</td>
                                                <td>{item.Percentage}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className="bold-column">Total</th>
                                                <td className="bold-column">{calculateColumnTotal('2014/2022')}</td>
                                                <td className="bold-column">{calculateColumnTotal('2022/2023')}</td>
                                                <td className="bold-column">{calculateColumnTotal('2023/2024')}</td>
                                                {/* <td className="bold-column">{calculateColumnTotal('2022/2023')}</td> */}
                                                <td className="bold-column">{calculateColumnTotal('Total')}</td>
                                                <td className="bold-column">{Math.round(calculateColumnTotal('Percentage'))}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                    </div>
                                </Col>
                                {/* <Col md={12}>
                                    <Apaexlinecolumn dataColors='["--bs-danger","--bs-primary", "--bs-success", "--bs-info" ]'/>
                                </Col> */}

                            </Row>
                        </Col>
                        <Col  md={1}/>
                    </Row>
                    <Label className='simple-content pt-3' >In 2023/2024 - 49% of the beneficiaries of the Trust were unemployed persons while self-employed persons (both skilled & unskilled) accounted for 51% of beneficiaries.  Persons who are unemployed (such as dependent women and other dependent elders who do not contribute to family income) have consistently accounted for more than 50% of our beneficiaries. While these persons are obviously not the breadwinners of the family, by supporting their surgeries we have to that extent provided relief to the breadwinners of those families who are ethically, morally and emotionally obliged to look after them in illness.</Label>

                </Container>
            </div>
        </div>
    );
}

export default employmentBreakdown;
