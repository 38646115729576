/* eslint-disable */
import PropTypes from "prop-types";
import React from 'react';
import { Button, Card, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import ConsultantArray from "../../assets/data/consultant.json";
import TrusteeComponet from "./trusteeComponet";

const boardOfTrustees = () => {
    return (
        <div>
            <div className="simple-container">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12" >
                            <Card body className=" custom-card-body pb-5">
                                <CardTitle className="quart mt-4 mb-4">“Not all of us can do great things. But we can do small things with great love”</CardTitle>
                                <CardText className="quart-owner">- Mother Teresa</CardText>
                                <Row  className="align-self-start text-start">
                                    <Col>
                                        <Button href="/#/contact" className='btn-basic-org  font-16' >
                                            Become a Fundraiser
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>


                        <h2 className="title mt-4 pb-4">
                            BOARD OF&nbsp;<span style={{ color: "#ED0F0F" }}>TRUSTEES&nbsp;</span>
                        </h2>

                        <Row>
                            {/* <Col md={4}>

                                <Card body className="card-shadow card-bg align-self-center text-center pt-4 pb-4">
                                    <CardTitle className='title'>Dr. Rajitha Y De Silva</CardTitle>
                                    <CardText className='subtitle' >Consultant<br/> <span>Cardiothoracic Surgeon</span> <br/> <span>Cardiothoracic Surgeon</span></CardText>
                                </Card>
                            </Col> */}
                            {ConsultantArray.map((item) => (
                                <Col md={3} sm={6} key={item.id}>
                                    <TrusteeComponet  name={item.name} area={item.area} title={item.title} positon={item.positon} />
                                </Col>
                            ))}
                        </Row>

                    </Row>
                </Container>

            </div>
        </div>
    );
}

boardOfTrustees.propTypes = {
    classes: PropTypes.object
};

export default boardOfTrustees;
