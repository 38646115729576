import { combineReducers } from "redux";
import donerReducer from './rtk-store/slices/donerSlice';

import Layout from "./layout/reducer";


const rootReducer = combineReducers({
  Layout,
  doner: donerReducer,
});

export default rootReducer;
