/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Button, Card, CardBody, Label } from 'reactstrap';
import Doctor from "../../assets/images/portfolio/Memorial_Portrait 1.png"
import img1 from '../../assets/images/portfolio/img1.jpg';
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EventComponet from "./eventComponet"
import EventArray from "../../assets/data/events.json";
import PropTypes from "prop-types";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const Event = () => {

    return (
        <div>
            <div className="simple-container">
                <Container>
                    <h2 className="title mt-1 mb-5"> Event</h2>
                    <Row className=''>
                        <>
                        <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true}
                        infinite={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        >
                            {EventArray.map((item) => (
                                <EventComponet key={item.id} eventimage={item.image} derscription={item.derscription} title={item.title} />
                            ))}
                        
                    </Carousel>
                        
                        
                        </>
                    </Row>
                    
                </Container>

            </div>
        </div>
    );
}

Event.propTypes = {
    classes: PropTypes.object
};

export default Event;
