import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { postMessage } from '../../services/postMessage';

const ContactUsForm = () => {
    
    const [fullName, setFullName] = useState('');  
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const isFormIncomplete = !fullName || !email || !subject || !message;
    const dispatch = useDispatch();

    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            
            const payload = {
                fullName: fullName,
                email: email,
                subject: subject,
                message: message
              };

            dispatch(postMessage(payload));
    
            setFullName('');   
            setEmail('');
            setSubject('');
            setMessage('');
    
            // Show success SweetAlert
            Swal.fire({
                icon: 'success',
                title: 'Message Sent',
                text: 'Your message has been sent successfully.',
            });
        } catch (error) {
            console.error('Error adding document: ', error);
    
            // Show error SweetAlert
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while sending your message. Please try again later.',
            });
        }
    };

    // Function to reset form data
    const handleReset = () => {
        setFullName('');   
        setEmail('');
        setSubject('');
        setMessage('');
    };

    return (
        <div className="contact-us-form">
        <Container>
            <Row>
                <Col md={12}>
                    <h1 className="title font-bold contactus-title">Interested in discussing?</h1>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Form  onSubmit={handleSubmit}>
                        <FormGroup className="custom-form-group-contact">
                            <Label htmlFor="fullName" className="custom-label">Name*</Label>
                            <Input
                                type="text"
                                className="form-control font-contact"
                                id="fullName"
                                placeholder="Full Name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}

                            />
                        </FormGroup>
                        <FormGroup className="custom-form-group-contact">
                        <Label htmlFor="address" className="custom-label">Email*</Label>
                        <Input
                            type="text"
                            className="form-control font-contact"
                            id="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            
                        />                       
                        </FormGroup>
                        <FormGroup className="custom-form-group-contact">
                        <Label htmlFor="address" className="custom-label">Subject *</Label>
                        <Input
                            type="text"
                            className="form-control font-contact"
                            id="email"
                            placeholder="Sybject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            
                        />                       
                        </FormGroup>
                        <FormGroup className="custom-form-group-contact">
                            <Label htmlFor="message" className="custom-label">Message</Label>
                            <textarea type="text" className="form-control font-contact" id="message" placeholder="Type here......"  value={message}  onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        </FormGroup>
                        <div className="button-group">
                            <Row>
                                <Col md={4}>
                                    <Button type="button" className="btn btn-border-org" onClick={handleReset}>Reset</Button>
                                </Col>
                                <Col md={8} className="text-md-end">
                                    <div className="d-flex justify-content-md-end">
                                        <Button type="submit" disabled={isFormIncomplete} className="btn btn-basic-org">Send your message</Button>
                                    </div>
                                </Col>                             
                            </Row>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    </div>
    );
}

export default ContactUsForm;
