import React from 'react';
import { Col, Container, Label, Row, Table } from 'reactstrap';
import data from "../../assets/data/Progress/gender.json";
import Apaexlinecolumn from './Charts/genderapaexlinecolumn';


const genderBreakdown = () => {
    
    const calculateColumnTotal = (columnName) => {
        return data.reduce((total, item) => total + (item[columnName] || 0), 0);
      };
    return (
        <div className="pt-5">
            <div className="pt-3">
                <Container>
                    <Row className="justify-content-center text-center progreport-content">
                        <Label className='title pb-3' >BREAKDOWN OF PATIENTS AIDED BY GENDER</Label>
                        {/* <Col md={1} /> */}
                        {/* <Col  md={10}><img src={GenderChart}  /></Col> */}
                        <Col  md={12}>
                            <Row>
                                <Col md={7}>
                                <div className='table-responsive'>
                                    <Table className="table  mb-0">
                                    <thead className="table-light">
                                    <tr>
                                    <th>Gender</th>
                                    <th>2014/2022</th>
                                    <th>2022/2023</th>
                                    <th>2023/2024</th>
                                    {/* <th>2022/2023</th> */}
                                    <th>Total</th>
                                    <th>Percentage</th>
                                    </tr>
                                </thead>
                                <tbody>

                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td className="bold-column">{item.gender}</td>
                                        <td>{item['2014/2022']}</td>
                                        <td>{item['2022/2023']}</td>
                                        <td>{item['2023/2024']}</td>
                                        {/* <td>{item['2022/2023']}</td> */}
                                        <td>{item.Total}</td>
                                        <td>{item.Percentage}</td>
                                    </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th className="bold-column">Total</th>
                                        <td className="bold-column">{calculateColumnTotal('2014/2022')}</td>
                                        <td className="bold-column">{calculateColumnTotal('2022/2023')}</td>
                                        <td className="bold-column">{calculateColumnTotal('2023/2024')}</td>
                                        {/* <td className="bold-column">{calculateColumnTotal('2022/2023')}</td> */}
                                        <td className="bold-column">{calculateColumnTotal('Total')}</td>
                                        <td className="bold-column">{calculateColumnTotal('Percentage')}</td>
                                    </tr>
                                </tfoot>
                                    </Table>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <Apaexlinecolumn dataColors='["--bs-danger","--bs-primary", "--bs-success", "--bs-info" ]'/>
                                </Col>
                            </Row>
                        </Col>
                        <Col  md={1}/>
                    </Row>
                    <Label className='simple-content pt-3' >The Male: Female ratio of beneficiaries has consistently around 2:1</Label>

                </Container>
            </div>
        </div>
    );
}

export default genderBreakdown;
