import React from 'react';
import { Card, CardTitle, CardText } from 'reactstrap';

const BankDetailsCard = ({ name, bankName, BankLogo, accountName, accountNo, swiftCode, bankCode, branchCode }) => {
    return (
        <div>
            <Card body className="card-shadow bankTransferCard data-fonts">
                <img src={BankLogo} alt="Bank Logo"  className='corner-image'/> {/* Use img tag for SVG */}
                <CardTitle>{name}</CardTitle>
                <CardText>
                    Bank Account Details <br />
                    Name of the Bank: {bankName}<br />
                    Name of Account: {accountName}<br />
                    Account No: {accountNo}<br />
                    Swift Code: {swiftCode}<br />
                    Bank Code: {bankCode}<br />
                    Branch Code: {branchCode}
                </CardText>
            </Card>
        </div>
    );
}

export default BankDetailsCard;
