import React from "react";
import PropTypes from "prop-types";


// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import HeaderBanner from "../../components/banner/banner";
import BannerReadContect from "../../components/AboutUS/bannerReadContect";
import BannerFounders from "../../components/AboutUS/bannerFounders";
import BoardOfTrustees from "../../components/AboutUS/boardOfTrustees";

const AboutUS = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner heading="About Us"/>

                    <BannerReadContect/>

                    <BannerFounders/>

                    <BoardOfTrustees/>
                    
                </div>
            </div>
            <Footer />
        </div>
    );
}

AboutUS.propTypes = {
    classes: PropTypes.object
};

export default AboutUS;
