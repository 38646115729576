import PropTypes from "prop-types";
import React from "react";
import MaintenanceImage from "../../assets/images/maintenance.png";

const UnderDev = () => {
    const goBack = () => {
        window.history.go(-1); // This navigates back one step in the history
    };

    return (
        <div id="main-wrapper" className="align-self-center text-center pt-5">
            <img src={MaintenanceImage} className="imgcenter pt-5" alt="Under Maintenance" />
            <p className="txtblue pt-4">
                Payment Gateway is <a className="txtyellow">under maintenance</a>.
            </p>
            <p className="txtblue">
                <strong>Thank You!</strong> for visiting.
            </p>
            <button className="goback-button" onClick={goBack}>Go Back</button>
        </div>
    );
}

UnderDev.propTypes = {
    classes: PropTypes.object
};

export default UnderDev;
