/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Container, Button, Label,Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'; // Import Modal
import OnlineDonation from "../../assets/images/icons/make-an-online-donation.png"
import Insurence from "../../assets/images/icons/property-insurance.png"
import ShakingHand from "../../assets/images/icons/shaking-hands-inside-a-heart.png"
import Swal from 'sweetalert2';
import Select from 'react-select';
import { postVoluntee } from '../../services/postVoluntee';

const bannerTrustFund = () => {
    const [showFullContent, setShowFullContent] = useState(false);
    const [showJoinUsPopup, setShowJoinUsPopup] = useState(false); // State for the Join Us popup
    
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [area, setArea] = useState('');
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();


    const areAllFieldsFilled = () => {
        return (
            fullName !== '' &&
            email !== '' &&
            dob !== '' &&
            phone !== '' &&
            gender !== '' &&
            area !== '' &&
            message !== ''
        );
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Initial step")
        try {
            const payload = {
                name: fullName,
                email: email,
                dob: dob,
                phone: phone,
                gender: gender,
                area: area,
                message: message
            };
            console.log("After payload step")
            dispatch(postVoluntee(payload));
    
            // Display a success alert
            await Swal.fire({
                icon: 'success',
                title: 'Thank you!',
                text: 'Your volunteer application has been submitted.',
            });
    
            // Clear the form after submission
            setFullName('');
            setEmail('');
            setDob('');
            setPhone('');
            setGender('');
            setArea('');
            setMessage('');
    
            // Close the popup
            toggleJoinUsPopup();
    
        } catch (error) {
            console.log(error);
            // Display an error alert
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong. Please try again later.',
            });
        }
    };
    
    const toggleJoinUsPopup = () => {
        setShowJoinUsPopup(!showJoinUsPopup);
        setFullName('');
        setEmail('');
        setDob('');
        setPhone('');
        setGender('');
        setArea('');
        setMessage('');
    };

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };

    

    const volunteerAreaOptions = [
        { value: 'Befriending & Mentoring', label: 'Befriending & Mentoring' },
        { value: 'Administrative / Office Work', label: 'Administrative / Office Work' },
        { value: 'Arts (Music / Drama / Crafts)', label: 'Arts (Music / Drama / Crafts)' },
        { value: 'Teaching / Tutoring / Supporting Learning', label: 'Teaching / Tutoring / Supporting Learning' },
        { value: 'Counselling / Listening', label: 'Counselling / Listening' },
        { value: 'Youth Work', label: 'Youth Work' },
        { value: 'Events / Stewarding', label: 'Events / Stewarding' },
        { value: 'Sports / Outdoor Activities / Coaching', label: 'Sports / Outdoor Activities / Coaching' },
        { value: 'Other - (Please specify on message)', label: 'Other - (Please specify on message)' },
    ];

    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
    ];

      
    return (
        <div>
            <div className='trustbanner-volunteers'>
                <Row className="justify-content-center pl-0 pr-0">
                <Col>
                    <div className="static-volunteers"></div>
                </Col>
                <Col>
                    <div className="gray-container-volunteers">
                    <Container className='align-self-center'>
                            <Row className="justify-content-start pl-0 pr-0">
                                <Col md={12} className="align-self-start text-start pl-0 pr-0">
                                    <h2 className="title">
                                   THE ROLE OF A&nbsp;<span style={{ color: "#ED0F0F" }}>VOLUNTEER&nbsp;</span>
                                    </h2>
                                    <h6 className="subtitle op-8 pt-3">
                                    If you have some time to spare and you also have a passion to help those in pain save their lives, why not join us as a volunteer and help us raise funds through our diverse fund raising activities. You may make the difference between life and death for an ailing heart patient who cannot afford the full cost of cardiac surgery.
                                    </h6>
                                </Col>
                                <Col md={6} className="align-self-start text-start pl-0 pr-0">
                                    <Button onClick={toggleJoinUsPopup} className='btn-basic-org m-t-20  font-15'>Join Us</Button>{' '}
                                </Col>
                            </Row>
                    </Container>
                            </div>
                </Col>
                </Row>
            </div>
            <Modal isOpen={showJoinUsPopup} toggle={toggleJoinUsPopup} size="lg">
                            <ModalHeader toggle={toggleJoinUsPopup}  >Become a Volunteer</ModalHeader>
                            <ModalBody>
                                <div>
                                    <Row>
                                        <Col md={1}/>
                                        <Col md={10}>
                                        <Row>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Full Name *</Label>
                                                <Input
                                                    className="vol-input"
                                                    placeholder="Enter Full Name"
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Email *</Label>
                                                <Input
                                                    className="vol-input"
                                                    placeholder="Enter Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Date of Birth *</Label>
                                                <Input
                                                    type="date"
                                                    className="vol-input"
                                                    placeholder="Enter Date of Birth"
                                                    value={dob}
                                                    onChange={(e) => setDob(e.target.value)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Mobile Number *</Label>
                                                <Input
                                                    className="vol-input"
                                                    placeholder="Enter Mobile Number"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Gender *</Label>
                                                <Select
                                                    options={genderOptions}
                                                    className="vol-input"
                                                    placeholder="Select Gender"
                                                    value={gender}
                                                    onChange={(selectedOption) => setGender(selectedOption)}
                                                />
                                            </Col>
                                            <Col md={6} className="pt-3">
                                                <Label className="vol-lable">Volunteer Area *</Label>
                                                <Select
                                                    options={volunteerAreaOptions}
                                                    className="vol-input"
                                                    placeholder="Select Volunteer Area"
                                                    value={area}
                                                    onChange={(selectedOption) => setArea(selectedOption)}
                                                />
                                            </Col>
                                            <Col md={12} className="pt-3">
                                                <Label className="vol-lable">Message *</Label>
                                                <Input
                                                    type="textarea"
                                                    className="vol-text-input"
                                                    placeholder="Write your message..."
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                            
                                        </Col>
                                        <Col md={1}/>
                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Col md={12} className="text-center">
                                    <Button onClick={handleSubmit} disabled={!areAllFieldsFilled()} className='btn-border-apply-org m-t-20 font-15' style={{ width: '100%' }}>
                                        Join Us
                                    </Button>
                                </Col>
                            </ModalFooter>

                        </Modal>
            
        </div>
    );
}

export default bannerTrustFund;
