import PropTypes from "prop-types";
import React, { useState } from "react";
import PDF from "react-pdf-js";
import { Button, Card, Container } from "reactstrap";
import useDeviceDetection from "../../hooks/useDeviceDetection";
import PdfViewer from "./PdfViewer";
import { data } from "./imagesData";
import { Image } from "react-bootstrap";

//images in gDrive login - heart2heart.amerck Amk@1234
const GreetingList = () => {
  const device = useDeviceDetection();
  return (
    <div className="simple-container">
      <Container>
        <h1>2024</h1>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {data.map((item, index) => (
            <Card className="card-shadow" style={{width:'20vw',height:'50vh'}} key={index}>
              <img
                src={item}
                width={"100%"}
                height='100%'
                style={{
                  objectFit: "contain",
                }}
                title={`Image ${index}`}
              ></img>
            </Card>
          ))}
        </div>
      </Container>
    </div>
  );
};

GreetingList.propTypes = {
  classes: PropTypes.object,
};

export default GreetingList;
