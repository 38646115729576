import React from 'react';
import { Button, Card, CardBody, Container, Label } from 'reactstrap';

const newsComponet = ({newsimage, sourceLink, title}) => {
    return (
        <div>
            <Container>
                <Card className="card-shadow" style={{height:"450px"}}>
                <div className="">
                    <a className="img-ho">
                        <img className="card-img-top" src={newsimage} alt={newsimage} />
                    </a>
                </div>

                    <CardBody className="d-flex justify-content-center flex-column align-items-center">
                        <Label className="catagory-content" style={{fontWeight: '700', textTransform: 'uppercase'}}>{title}</Label>
                        <Button href="/#/newsandstoties" className='btn-basic-org m-t-20 mb-4  font-15'>Join Us</Button>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default newsComponet;
