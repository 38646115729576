import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BankOfCeylonImage from "../../assets/images/Dontaion/bocBank.svg";
import ComBankImage from "../../assets/images/Dontaion/comBank.svg";
import BankDetailsCard from './BankDetailsCard';


const BankTransferCards = () => {
    return (
        <div style={{"paddingTop":115}}  className="bankTransaction">
             <Row className="justify-content-start" >
                    <Col md="7" className="text-center">
                    <h2 className='title font-bold headings bankTransaction'> Donate Via Bank Transactions </h2>
                    </Col>
                </Row>
       
        <Container>
        <Row>
            <Col md={6}>
            <BankDetailsCard 
        BankLogo={BankOfCeylonImage}
             name="BOC"
             bankName="BOC, Jayawardenapura Branch, Thalapathpitiya"
            accountName="Heart To Heart Trust Fund"
            accountNo="0076631276"
            swiftCode="BCEYLKLX"
            bankCode="7010"
            branchCode="520"
             />
            </Col>
      
      <Col md={6}>
      <BankDetailsCard 
            BankLogo={ComBankImage}
             name="Commercial Bank"
             bankName="Commercial Bank PLC, Borella"
            accountName="HEART TO HEART TRUST FUND "
            accountNo="1000230695"
            swiftCode="CCEYLKLX"
            bankCode="7056"
            branchCode="019"
             />
           
      </Col>
            
            
        </Row>
    </Container> 
    </div>
           
    );
}

export default BankTransferCards;
