import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const HeaderBanner = ({heading}) => {
    return (
        <div className="static-slider-head">
            <Container>
                <Row className="justify-content-start">
                    <Col lg="7" md="6"/>
                    <Col lg="5" md="6" className="align-self-start text-start">
                        <h1 className="title">{heading}<span style={{color:"red"}}>.</span></h1>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderBanner;
