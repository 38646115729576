import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Collapse, Container, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler, DropdownMenu, DropdownItem } from 'reactstrap';

import hthLogo from '../../assets/images/logos/hthlogo.png';
import MyDropdown from '../dropDownMenu';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);


    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW                                                   */
    /*--------------------------------------------------------------------------------*/

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Container className="po-relative">
                    <Navbar className="navbar-expand-lg h6-nav-bar">
                        <NavbarBrand href="/">
                            <img src={hthLogo} alt="wrapkit" style={{ width: '90px', height: '90px' }} />
                        </NavbarBrand>
                        <NavbarToggler onClick={toggle}><span className="ti-menu"></span></NavbarToggler>
                        <Collapse isOpen={isOpen} navbar className="hover-dropdown font-14 justify-content-end" id="h6-info">
                            <Nav navbar className="ms-auto">
                                <NavItem>
                                    <Link className="nav-link" to={"/aboutus"}>
                                        About Us
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/abhayadhana"}>
                                        Abhayadhanaya
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/donation"}>
                                        Donations
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/progress"}>
                                        Progress
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/volunteers"}>
                                        Volunteers
                                    </Link>
                                </NavItem>
                                {/* <NavItem>
                                    <Link className="nav-link" to={"/sample"}>
                                        Sample
                                    </Link>
                                </NavItem> */}
                                <NavItem>
                                    <Link className="nav-link" to={"/newsandstoties"}>
                                        News & Stories
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <MyDropdown />
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/contact"}>
                                        Contact Us
                                    </Link>
                                </NavItem>
                             
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Container>
            </div>
        </div>
    );

}
export default Header;
