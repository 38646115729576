import pdf1 from '../../assets/images/news/14November2019.jpg'



export const data = [
    'https://res.cloudinary.com/dottf2rmh/image/upload/v1723033858/h2h/auu7eqj9deynj5x9xo98.jpg',
    'https://res.cloudinary.com/dottf2rmh/image/upload/v1723033859/h2h/gve5nfl5pvi9ar3kpdwo.jpg',
    'https://res.cloudinary.com/dottf2rmh/image/upload/v1723033859/h2h/lgvwkfx0nfns6vkwmncw.jpg',
    'https://res.cloudinary.com/dottf2rmh/image/upload/v1723033858/h2h/paanot52mos9jhtabzn3.jpg',
    'https://res.cloudinary.com/dottf2rmh/image/upload/v1723033858/h2h/pled2urgc0yxsdgd8pga.jpg'
  ]