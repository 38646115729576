/* eslint-disable */
import React, { useState } from 'react';
import { Row, Col, Container, Button, Label } from 'reactstrap';
import OnlineDonation from "../../assets/images/icons/make-an-online-donation.png"
import Insurence from "../../assets/images/icons/property-insurance.png"
import ShakingHand from "../../assets/images/icons/shaking-hands-inside-a-heart.png"

const howToBecameVolBanner = () => {

      
    return (
        <div>
            <div className='trustbanner'style={{ paddingTop :'100px' }}>
                <Row>
                    <div className="poster-howtobecame">
                        <Container className='align-self-center'>
                                <Row className="justify-content-start pl-0 pr-0">
                                    <Col md={12} className="align-self-start text-start pl-0 pr-0">
                                        <h2 className="title">
                                    HOW WE BECAME &nbsp;<span style={{ color: "#ED0F0F" }}>VOLUNTEER&nbsp;</span>
                                        </h2>
                                        <h6 className="subtitle op-8 pt-3" style={{paddingBottom : '15px'}}>
                                        “My keenness to join hands in trying to call for donors to help financially, originated after I went through a bypass surgery by Dr. Rajitha De Silva, who took great care to operate on me. Dr. Rajitha’s dedication to help people came to light when I saw the final bill, which was more than the package price due to me having stayed longer than expected. I was surprised to note that Dr. Rajitha had instructed the hospital to set off all extra dues from his personal fees. It was then that I learnt of his trust fund to help the needy heart patients who are dying due to financial difficulties. I promptly contributed to the fund with great pleasure and my daughter followed suit from California. I appeal to all good souls and organisations, to join hands in helping the Trust Fund in whichever way you can assist financially to save lives, which is a meritorious deed which will bring you peace, joy and happiness with the blessing of the triple gem.”
                                        </h6>
                                        <div className='name '>
                                        -Sarath Wimalaratne   
                                            </div>                               
                
                                    </Col>                                
                                </Row>
                        </Container>
                    </div>
                </Row>


    
                <Col>
                    <div className="image-container"></div>
                </Col>
 

                <Row>
                <div className="poster-howtobecame" style={{paddingTop : '106px'}}>
                        <Container className='align-self-center'>
                                <Row className="justify-content-start pl-0 pr-0" >
                                    <Col md={12} className="align-self-start text-start pl-0 pr-0">
                                      
                                        <h6 className="subtitle op-8" style={{paddingBottom : '15px'}} >
                                        “A few of our family members underwent heart related surgeries in private hospitals, where, as we all know, charges are exorbitant. It then occurred to us, how could a poor patient undergo such financial strain, and when they cannot afford the cost would they not be compelled to patiently be in the waiting list in a government hospital which would be in fact at the risk of their life.Coincidently, with this uneasy feeling still lingering in our minds, we came across an article in the Sunday Times of 18th January 2015, where Dr. Rajitha De Silva and Dr. Ruvan Ekanayaka spoke about their Trust Fund. We lost no time to get in touch with them and offer our services as volunteers. We feel happy we took this step as we reflect more and more on their concept and implementation process and their transparency in the operating of this Fund. Furthermore, the untiring efforts of the two eminent doctors, the Co-Founders, enriched with humility, empathy, honesty and competence, which we observed as time went by, provided a tremendous energy drawn of human warmth and compassion for volunteers like us to support.<br/>
We encourage more volunteers to come forward like we did to support this worthy cause and help impoverished heart patients needing surgery to win their battle between life and death. There is always an Almighty above to reward you.”

</h6>                                        <div className='name'>
                                        -Sharmeen and Kareem Amath
                                            </div>                               
                
                                    </Col>                                
                                </Row>
                        </Container>
                    </div>
                </Row>
            </div>    
        </div>
    );
}

export default howToBecameVolBanner;
