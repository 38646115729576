/* eslint-disable */
import React, {useState} from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import H2HVideo from "../../assets/videos/H2HIntro.mp4"
import H2HVideoThumbnail  from "../../assets/videos/H2HVideoThumbnail.jpg"

const bannerVolunteer = () => {

    const [showVideo, setShowVideo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleThumbnailClick = () => {
        setIsLoading(true);
        setShowVideo(true);
      };
      

    return (
        <div>
            <div className="spacer">
            <Container>
            <Row className="justify-content-center pb-5">
                <div className="video-container text-center">
                    {isLoading && (
                        <div className="loading-spinner">
                            {/* Add your loading spinner here */}
                        </div>
                    )}
                    {showVideo ? (
                        <video
                            width="100%"
                            height="100%" // Adjust this as needed
                            controls
                            autoPlay
                            onLoadedData={() => setIsLoading(false)}
                        >
                            <source src={H2HVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img
                            src={H2HVideoThumbnail}
                            width="100%"
                            height="100%"
                            alt="Video Thumbnail"
                            style={{ cursor: 'pointer' }}
                            onClick={handleThumbnailClick}
                            className="mx-auto" // Add this class to center the image horizontally
                        />
                    )}
                </div>
            </Row>
                </Container>



            </div>
            <div className="static-vontenteer gray-container">
                <Container>
                    <Row className="justify-content-center pt-3">
                        <Col md="6"/>
                        <Col md="6" className="align-self-start text-start">
                            <h2 className="title mt-4">
                            THE ROLE OF A&nbsp;<span style={{ color: "#ED0F0F" }}>VOLUNTEER&nbsp;</span>
                            </h2>
                            <h6 className="subtitle op-8 pt-4 pb-3">
                            The Heart to Heart Trust Fund is a charitable fund.The Heart To Heart Trust
                            Fund is a beacon of hope for financially impoverished heart patients across the
                            country. When treatment though cardiac surgery is beyond their financial means,
                            the Fund revives their hopes by providing financial assistance to impoverished
                            heart patients to help save their lives and brighten their future.
                            </h6>
                            <Button href="/#/volunteers" className='btn-basic-org m-t-20 mb-4 font-15'>Join Us</Button>{' '}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default bannerVolunteer;
