/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const bannerAbhayaProgram = () => {
    return (
        <div className=" ">
            
            <div className='trustbanner'>
                <Row className="justify-content-center pl-0 pr-0">
                    <Col  className="align-self-center text-center">
                        <div className="gray-container">
                            <Container>
                                <Row className="justify-content-start pl-0 pr-0">
                                <Col md={12} className="align-self-start text-start pl-0 pr-0">
                                    <h2 className="title">
                                    THE&nbsp;<span style={{ color: "#ED0F0F" }}>ABHAYADANAYA&nbsp;</span>PROGRAMME
                                    </h2>
                                    <h6 className="subtitle content-phara op-8 pt-3">
                                        A cardiac patient represents a life that is struggling between life and death. In the absence of timely surgery, there is a great chance that the patient will not survive. Intervening with finan­cial assistance to facilitate a heart surgery could very well negate death and save the life of the patient concerned – a spiritually rewarding act indeed on the part of the donor. This is the mean­ing of Abhayadanaya. What better way to com­memorate an event or anniversary or celebrate a wedding or birthday than to save the life of a cardiac patient with a part of the money that would otherwise be spent on jollity and wasteful indulgence on that day?
                                        Individuals, groups of persons, institutional entities and corporate entities can sponsor or partially sponsor financial assistance to indi­vidual patients needing financial assistance to meet the cost of a cardiac surgery. The fund arranges direct contact and face-to-face com­munication between the patient and the sponsor/ group of sponsors. They can meet the patient in hospital and personally hand over the money to him/her, thereby helping redeem the life of a heart patient through cardiac surgery.
                                        We find compassionate individuals and/or groups of individuals such as a batch of students or alumni sponsoring or partially sponsoring individual patients through the Abhayadanaya Programme on birthdays, anniversaries and other such special occasions. Corporate and institutional entities can do so on special occa­sions such as an annual general meeting or at the launching of a new venture. For the patient or patients concerned, this is an intensely emo­tional and invigorating experience when they find a compassionate individual or individuals coming to their bedside, caring for them and handing over a contribution towards meeting the cost of a cardiac surgery that will surely redeem their life.
                                        This innovative programme has proved to be very popular.
                                    </h6>
                                </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                        
                </Row>
            </div>
            
        </div>
    );
}

export default bannerAbhayaProgram;
