/* eslint-disable */
import React, { useState } from 'react';
import { Row, Col, Container, Button, Label } from 'reactstrap';


const bannerReadContect = () => {
    const [showFullContent, setShowFullContent] = useState(false);

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };
      
    return (
        <div>
            <div className="pt-3 aboutus-contents">
                <Container>
                    <Row className="justify-content-start catagory-content pt-4 pb-4">
                        <Label >Under the guidance of our Board of Trustees composed of eminent persons with a track record of reliable integrity, we always ensure the highest level of transparency in our operations. The Trustee Board is composed of both medical and non medical persons who represent various professional fields such as law, medicine, diplomacy, corporate business and the social sciences.</Label>
                    </Row>
                </Container>
            </div>
            <div className='aboutusbanner'>
                <Container>
                <Row className="justify-content-start catagory-content pt-4 textphara">
                        <Label>
                            {showFullContent ? (
                                <>
                                    <Label >
                                    The Trustees on the Board, representing the medical fraternity are Consultant Cardiothoracic Surgeon Dr. Rajitha Y De Silva, Senior Consultant Cardiologist Dr. Ruvan Ekanayaka, Consultant Cardiothoracic Surgeon Dr. Aruna Kapuruge, Consultant Cardiothoracic Surgeon, Dr. Mahendra Munasinghe, Consultant Cardiac Anaesthetist Dr. Kanishka Indraratne, Senior Consultant Cardiothoracic Anaesthetist and Intensivist Dr. Kumudini Ranatunga and Prof. S D Jayarante, the Chairman, Sri Jayewardenepura General Hospital (Ex-Officio). Additionally, the non-medical Trustees providing guidance to the Trust Fund are former Ambassador and UN Under-Secretary-General Jayantha Dhanapala, former Attorney General PC Palitha Fernando, Arunashantha de Silva PC, former Legal Draftsman, Mahendra Amarasuriya, Senior Banker, Philanthropist, and Former International President, Lions Club International 2007/08, Dr. Kumari Jayawardena, Author/Social Worker, Sudath Tennakoon, Chairman, Central Bearings, Lt. Gen. (retd.) Jagath Dias, former Chief of Staff, Sri Lanka Army, Sunimal Fernando, Senior Sociologist, Ajita de Zoysa, Philanthropist, Chairman of Kalutara Bodhi Trust and Chairman of Musaeus College and Kumar Sangakkara, former Captain Sri Lanka Cricket 2009-2011 and President of the Marylebone Cricket Club (MCC). <br/><br/>
                                    </Label>
                                    <Label>
                                    Apart from the members of our Board, we have received dedicated service and support from all our volunteers among whom I would like to mention Sister Champa Samarasinghe, Sharmeen and Kareem Amath and our Honorary Executive Coordinator Shanti Fernando. Since we are managing funds and donations, maintaining the highest possible level of trans­parency is a major factor contributing to our success. <br/>
                                        We have three sources of funding;
                                        <ol>
                                            <li>Donations from individuals, institutional and corporate entities.</li>
                                            <li>Fundraising events, organised by our highly committed group of volunteers, most of whom were once heart patients who have now fully recovered and work hard when required, to reach our goals and objectives. In addition, we also raise funds by working in collaboration with organisations such as the Lions Club.</li>
                                            <li>The Abhayadanaya Programme is yet another fundraiser we have organised.</li>
                                        </ol>
                                       
                                        
                                        We have supported more than 160 heart surgeries through our trust fund. When examining the eligibility of patients to receive financial support, we prioritize their clinical urgency and financial background. We give priority to breadwinners of families as there is an enormous responsibility on their shoulders. A breadwinner can usually get back to a normal life two months after an open heart surgery or two weeks after a keyhole cardiac surgery, fend once again for the family and contribute at the same time to the national workforce. At the moment, our office is situated at the Sri Jayewardenepura General Hospital (SJGH). Today we can proudly say that with the financial assistance provided by the trust fund, not a patient requiring heart surgery is turned away from the fee-levying SJGH because they cannot afford the cost. We are now in a position to extend our financial assistance on the same terms to financially disempowered patients in other private hospitals requiring urgent cardiac surgery.
                                    </Label>
                                </>
                            ) : (
                                <>
                                    <Label >
                                    The Trustees on the Board, representing the medical fraternity are Consultant Cardiothoracic Surgeon Dr. Rajitha Y De Silva, Senior Consultant Cardiologist Dr. Ruvan Ekanayaka, Consultant Cardiothoracic Surgeon Dr. Aruna Kapuruge, Consultant Cardiothoracic Surgeon, Dr. Mahendra Munasinghe, Consultant Cardiac Anaesthetist Dr. Kanishka Indraratne, Senior Consultant Cardiothoracic Anaesthetist and Intensivist Dr. Kumudini Ranatunga and Prof. S D Jayarante, the Chairman, Sri Jayewardenepura General Hospital (Ex-Officio). Additionally, the non-medical Trustees providing guidance to the Trust Fund are former Ambassador and UN Under-Secretary-General Jayantha Dhanapala, former Attorney General PC Palitha Fernando, Arunashantha de Silva PC, former Legal Draftsman, Mahendra Amarasuriya, Senior Banker, Philanthropist, and Former International President, Lions Club International 2007/08, Dr. Kumari Jayawardena, Author/Social Worker, Sudath Tennakoon, Chairman, Central Bearings, Lt. Gen. (retd.) Jagath Dias, former Chief of Staff, Sri Lanka Army, Sunimal Fernando, Senior Sociologist, Ajita de Zoysa, Philanthropist, Chairman of Kalutara Bodhi Trust and Chairman of Musaeus College and Kumar Sangakkara, former Captain Sri Lanka Cricket 2009-2011 and President of the Marylebone Cricket Club (MCC).<br/><br/> 
                                            
                                    Apart from the members of our Board, we have received dedicated service and support from all our volunteers among whom I would like to mention Sister Champa Samarasinghe, Sharmeen and Kareem Amath and our Honorary Executive Coordinator Shanti Fernando. Since we are managing funds and donations, maintaining the highest possible level of trans­parency is a major factor contributing to our success. <br/>
                                        We have three sources of funding;
                                        <ol>
                                            <li>Donations from individuals, institutional and corporate entities.</li>
                                            <li>Fundraising events, organised by our highly committed group of volunteers, most of whom were once heart patients who have now fully recovered and work hard when required, to reach our goals and objectives. In addition, we also raise funds by working in collaboration with organisations such as the Lions Club.</li>
                                            <li>The Abhayadanaya Programme is yet another fundraiser we have organised.</li>
                                        </ol>
                                    </Label>
                                    
                                </>
                            )}
                        </Label>
                    </Row>
                    <Row>
                        <Col md={6} className="align-self-start text-start trust-values">
                            <Button className='btn-basic-org  font-15' onClick={toggleContent}>
                                {showFullContent ? 'Read Less' : 'Read More'}
                            </Button>
                        </Col>
                    </Row> 
                </Container>
            </div>
            
        </div>
    );
}

export default bannerReadContect;
