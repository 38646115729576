import PropTypes from "prop-types";
import React from "react";

import { Col, Row } from 'reactstrap';

// core components
import ContactUsForm from "../../components/Contact/ContactUsForm";
import Details from "../../components/Contact/Details";
import Location from "../../components/Contact/Location";
import HeaderBanner from "../../components/banner/banner";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const ContactPage = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner heading="Contact Us" />

                    {/* <Location/> */}

                    {/* <BannerTrustFund/>

                    <BannerVolunteer/> */}
                    <div className="gray-container">
                    <Row>
                        <Col md={6}>
                                <Location/>
                        </Col>
                        <Col md={6}>
                            <ContactUsForm />
                        </Col>
                    </Row>
                </div>


                    

                  

                    <Details/>
                    
                </div>
            </div>
            <Footer />
        </div>
    );
}

ContactPage.propTypes = {
    classes: PropTypes.object
};

export default ContactPage;
