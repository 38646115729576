import React from "react";
import PropTypes from "prop-types";


// core components
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import HeaderBanner from "../../components/banner/banner";
import BannerProgressReport from "../../components/Progress/bannerProgressReport";
import ProvinceBreakdown from "../../components/Progress/provinceBreakdown";
import EmploymentBreakdown from "../../components/Progress/employmentBreakdown";
import GenderBreakdown from "../../components/Progress/genderBreakdown";
import AgeBreakdown from "../../components/Progress/ageBreakdown";
import HospitalBreakdown from "../../components/Progress/hospitalBreakdown";
import SurgeryBreakdown from "../../components/Progress/surgeryBreakdown";

// // sections for this page
// import Buttons from "./sections/buttons.jsx";
// import Labels from "./sections/labels.jsx";
// import PagePagination from "./sections/pagination.jsx";
// import Images from "./sections/images.jsx";
// import Breadcrumbs from "./sections/breadcrumbs.jsx";
// import Cards from "./sections/cards.jsx";
// import Dropdowns from "./sections/dropdowns.jsx";
// import PageForm from "./sections/form.jsx";
// import PageTable from "./sections/table.jsx";
// import Notification from "./sections/notification.jsx";
// import TooltipPopover from "./sections/tooltip-popover.jsx";
// import Typography from "./sections/typography.jsx";
// import JsComponents from "./sections/js-components.jsx";
// import CallToAction from "../../components/call-to-action/CallToAction"



const HomePage = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner heading="Progress"/>

                    <BannerProgressReport/>

                    <ProvinceBreakdown/>

                    <EmploymentBreakdown/>

                    <GenderBreakdown/>

                    <AgeBreakdown/>

                    <HospitalBreakdown/>

                    <SurgeryBreakdown/>

                </div>
            </div>
            <Footer />
        </div>
    );
}

HomePage.propTypes = {
    classes: PropTypes.object
};

export default HomePage;
